import React, { useState } from 'react'
import hero1 from '../assets/hero/hero1.png'
import hero2 from '../assets/hero/hero2.png'
import hero3 from '../assets/hero/hero3.png'
import hero3b from '../assets/hero/hero3b.png'
import hero4 from '../assets/hero/hero4.png'
import TryEpahubb from '../pages/TryEpahubb'

const Hero = () => {
    const [projectModal, setProjectModal] = useState(false)
  return (
    <div id='home' className='bg-[#FEF2ED] md:h-screen py-40'>
        {
            projectModal && <TryEpahubb setModal={setProjectModal} />
        }
        <div className="container">
            {/* IMAGES */}
            <div className='flex gap-1 items-center justify-center md:gap-3'>
                <div>
                    <img src={hero1} alt="" className='8xl:h-[300px] lg:h-[200px] md:h-[150px] h-[100px]'/>
                </div>
                <div>
                    <img src={hero2} alt=""  className='8xl:h-[300px] lg:h-[200px] md:h-[150px] h-[100px]' />
                </div>
                <div className='flex'>
                    <img src={hero3} alt=""  className='8xl:h-[300px] lg:h-[200px] md:h-[150px] h-[100px]' />
                    <img src={hero3b} alt=""  className='8xl:h-[300px] lg:h-[200px] md:h-[150px] h-[100px]' />
                </div>
                <div>
                    <img src={hero4} alt=""  className='8xl:h-[300px] lg:h-[200px] md:h-[150px] h-[100px]' />
                </div>
                
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 mt-5 items-center text-center"  >
                <h1 className='text-[#091542] font-[200] 8xl:text-[90px] lg:text-[80px] md:text-[60px] text-[30px] w-2/3 mx-auto lg:w-auto lg:leading-[80px] leading-8 md:leading-[60px] my-7 lg:my-0' data-aos="fade-right" data-aos-duration="1000">
                    Innovative <span className='font-[700]'>Product</span> Engineering
                </h1>
                <div className="flex-flex-col gap-3" data-aos="fade-left" data-aos-duration="1000">
                    <p className='text-[#091542] 8xl:text-[20px] md:text-[18px] text-[14px]'>
                        Epahubb Consult aims to deliver high-quality services and innovative solutions, focusing on areas such as UI/UX design, software engineering, and digital marketing.
                    </p>
                    <button onClick={()=>setProjectModal(true)} className='bg-[#016C90] px-7 py-2 rounded-lg text-white mt-3'>
                        Try Epahubb
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero