import React, { useState } from "react";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import PhoneInput from "react-phone-number-input";
import success_img from "../assets/successImg.png";
import { projectCategoriesList } from "../utils/data";
import { FaMinus } from "react-icons/fa6";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";

const TryEpahubb = ({ setModal }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [errors, setErrors] = useState([])
  const [submittingProject, setSubmittingProject] = useState(false)
  const [projectData, setProjectData] = useState({
    project_type: "Personal",
    email_notification:false,
    phone_notification:false
  });

  const handleFormChange = (e) => {
    setProjectData({
      ...projectData,
      [e.target.name]: e.target.value,
    });
  };

  const [projectCategories, setProjectCategories] = useState([
    {
      category: "",
      sub_category: "",
    },
  ]);

  const addCategoryField = () => {
    const object = {
      category: "",
      sub_category: "",
    };
    setProjectCategories([...projectCategories, object]);
  };

  const removeProjectCategory = (index) => {
    const projectCategoriesData = [...projectCategories];
    projectCategoriesData.splice(index, 1);
    setProjectCategories(projectCategoriesData);
  };

  const handleCategoryChange = (e, index) => {
    const projectCategoriesData = [...projectCategories];
    projectCategoriesData[index][e.target.name] = e.target.value;
    setProjectCategories(projectCategoriesData);
  };

  const validateFormData = () => {
    // check for errors
    let formErrors= []
    
    if(!projectData?.client_name){
        
        formErrors.push("Please enter your name")
    }
    if(!projectData?.client_email){
        formErrors.push("Please enter your email address")
    }
    if(!projectData?.client_phone){
        formErrors.push("Enter your phone number")
    }
    if(!projectData?.project_name){
        formErrors.push("Enter the project title")
    }
    if(!projectData?.project_description){
        formErrors.push("Enter the project description")
    }
    if(projectCategories[0]?.category === "" && projectCategories[0]?.sub_category === ""){
        formErrors.push("Specify at least one category and subcategory your project falls under")
    }
    if(!projectData?.estimated_budget){
        formErrors.push("Specify your budget")
    }
    setErrors(formErrors)
    
  }

  const initiateProject = async () => {
    if(errors?.length > 0){
      
      return
    }
    setSubmittingProject(true)
 

    const newProjectData = {
        ...projectData,
        "project_categories":projectCategories,
        
    }

    

    const projectFormData = JSON.stringify(newProjectData)

    

    const config = {
        headers:{
            'Content-Type':'application/json'
        }
    }
    
    await axios.post("https://app.epahubb.com/create-deal/",projectFormData, config).then((response)=>{
        setSubmittingProject(false)
        if(response.data?.success){
            setActiveTab(5)
        }else{
            setErrors(["Failed to create project"])
        }
    }).catch((error)=>{
        console.log(error)
        setSubmittingProject(false)
    })


  }

  return (
    <div
      id="modal"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal"
    >
      <div className="bg-white rounded-lg shadow-lg w-4/5 max-h-screen overflow-auto">
        <div className="px-6 py-4 border-b text-black items-center flex justify-between">
          <h3 className="text-lg font-semibold">Create Project</h3>
          <IoMdClose
            className="cursor-pointer"
            onClick={() => setModal(false)}
          />
        </div>
        <div className="px-4 lg:w-2/3 mx-auto my-7">
          <div className="">
            <div className="flex items-center gap-4 overflow-x-auto">
              <p
                className={`px-5 py-2 ${
                  activeTab === 1
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-black"
                }`}
              >
                Project Type
              </p>
              <p
                className={`px-5 py-2 ${
                  activeTab === 2
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-black"
                }`}
              >
                Project Settings
              </p>
              <p
                className={`px-5 py-2 ${
                  activeTab === 3
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-black"
                }`}
              >
                Budget
              </p>
              <p
                className={`px-5 py-2 ${
                  activeTab === 4
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-black"
                }`}
              >
                Summary
              </p>
              <p
                className={`px-5 py-2 ${
                  activeTab === 5
                    ? "text-blue-500 border-b-2 border-blue-500"
                    : "text-black"
                }`}
              >
                Completed
              </p>
            </div>

            {activeTab === 1 && (
              <div className="py-16">
                <h3 className="font-[500]">Project Type</h3>
                <div className="mt-2">
                  <div
                    htmlFor="personal-project"
                    onClick={() => {
                      setProjectData({
                        ...projectData,
                        ["project_type"]: "Personal",
                      });
                    }}
                    className={`${
                      projectData["project_type"] === "Personal"
                        ? "border-blue-500 bg-blue-200"
                        : "border-gray-500 bg-gray-200"
                    } border-2 rounded-lg border-dashed cursor-pointer w-full px-10 py-5 block text-[20px]`}
                  >
                    Personal Project
                  </div>
                </div>

                <div className="mt-2">
                  <div
                    htmlFor="personal-project"
                    onClick={() => {
                      setProjectData({
                        ...projectData,
                        ["project_type"]: "Corporate",
                      });
                    }}
                    className={`${
                      projectData["project_type"] === "Corporate"
                        ? "border-blue-500 bg-blue-200"
                        : "border-gray-300 bg-gray-100"
                    } border-2 rounded-lg border-dashed cursor-pointer w-full px-10 py-5 block text-[20px]`}
                  >
                    Team Project
                  </div>
                </div>
              </div>
            )}

            {activeTab === 2 && (
              <div className="py-16">
                <h3 className="font-[500]">Project Settings</h3>

                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    onChange={(e) => handleFormChange(e)}
                    type="text"
                    name="client_name"
                    required
                    value={projectData?.client_name}
                    className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                  />
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    onChange={(e) => handleFormChange(e)}
                    type="email"
                    name="client_email"
                    required
                    value={projectData?.client_email}
                    className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                  />
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Phone number <span className="text-red-500">*</span>
                  </label>
                  <PhoneInput
                    defaultCountry="GH"
                    placeholder="Enter phone number"
                    value={projectData?.client_phone}
                    onChange={(value) => {
                      setProjectData({
                        ...projectData,
                        client_phone: value,
                      });
                    }}
                  />
                </div>

                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Project Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    onChange={(e) => handleFormChange(e)}
                    type="text"
                    name="project_name"
                    required
                    value={projectData?.project_name}
                    className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                  />
                </div>

                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Project Description <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                    name="project_description"
                    onChange={(e) => handleFormChange(e)}
                    id=""
                    cols="30"
                    rows="3"
                    value={projectData?.project_description}
                  ></textarea>
                </div>

                {projectCategories?.map((project_category, index) => (
                  <div
                    className="grid lg:grid-cols-5 items-center gap-3"
                    key={index}
                  >
                    <div className="flex flex-col col-span-2 gap-1 mt-4">
                      <label htmlFor="">
                        Category <span className="text-red-500">*</span>
                      </label>
                      <select
                        onChange={(e) => handleCategoryChange(e, index)}
                        className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                        name="category"
                        id=""
                      >
                        <option value="">Select Category {projectCategories[index]?.category}</option>
                        {
                            projectCategoriesList?.map((category, i)=>(
                                <option value={`${category?.category}`} key={i} selected={projectCategories[index]?.category === category?.category}>
                                    {category?.category}
                                </option>
                            ))
                        }
                        
                      </select>
                    </div>
                    <div className="flex flex-col col-span-2 gap-1 mt-4">
                      <label htmlFor="">
                        Sub Category <span className="text-red-500">*</span>
                      </label>
                      <select
                        onChange={(e) => handleCategoryChange(e, index)}
                        className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                        name="sub_category"
                        id=""
                      >
                        <option value="">Select Sub Category </option>
                        {
                            projectCategoriesList.filter((category) => category?.category === projectCategories[index]['category'])[0]?.subcategories?.map((sub_category, i)=>(
                                <option value={sub_category} key={i} selected={projectCategories[index]?.sub_category === sub_category}>
                                    {sub_category}
                                </option>
                            ))
                        }
                      </select>
                    </div>
                    <div className="flex items-center justify-center mt-12 gap-3">
                      <div
                        className="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
                        onClick={() => addCategoryField()}
                      >
                        <IoMdAdd />
                      </div>
                      {index > 0 && (
                        <div
                          className="h-6 w-6 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer"
                          onClick={() => removeProjectCategory(index)}
                        >
                          <FaMinus />
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Notifications <span className="text-red-500">*</span>
                  </label>
                  <div className="flex lg:flex-row flex-col justify-between">
                    <p>Allow notifications by phone or email</p>
                    <div className="flex gap-4">
                      <div className="flex gap-2">
                        <input type="checkbox" name="" id="" checked={projectData?.phone_notification} onChange={(e)=>
                            setProjectData({
                            ...projectData, ['phone_notification'] : e.target.checked ? true : false
                        })} />
                        <span>Phone</span>
                      </div>
                      <div className="flex gap-2">
                        <input type="checkbox" name="" id="" checked={projectData?.email_notifications} onChange={(e)=>setProjectData({
                            ...projectData, ['email_notification'] : e.target.checked ? true : false
                        })} />
                        <span>Email</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 3 && (
              <div className="py-16">
                <h3 className="font-[500]">Budget</h3>
                <div className="flex flex-col gap-1 mt-4">
                  <label htmlFor="">
                    Setup Budget(GHS) <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    required
                    name="estimated_budget"
                    value={projectData?.estimated_budget}
                    onChange={(e) => handleFormChange(e)}
                    className="px-3 py-2 bg-[#F4F4F4] rounded outline-none"
                  />
                </div>
              </div>
            )}

            {activeTab === 4 && (
              <div className="py-16">
                {
                    errors?.length > 0 && (
                        <div className="mt-5">
                            {
                                errors?.map((error,index)=>(
                                    <p className="bg-red-50 border border-red-500 rounded px-4 py-2 text-red-700 mb-3 " key={index}>
                                        {error}
                                    </p>
                                ))
                            }
                        </div>
                    )
                }
                <h3 className="font-[500] mb-6">Summary</h3>
                <h4 className="font-[600]">
                  Project Type <span className="text-red-500">*</span>
                </h4>
                <p>{projectData?.project_type}</p>

                <h4 className="font-[600] mt-6">
                  Project Settings <span className="text-red-500">*</span>
                </h4>
                <div>
                  <p>Name: {projectData?.client_name}</p>
                  <p>Email: {projectData?.client_email}</p>
                  <p>Number: {projectData?.client_phone}</p>
                  <p>Project Title: {projectData?.project_name}</p>
                  <p>Project Description: {projectData?.project_description}</p>
                </div>

                
                <h4 className="font-[600] mt-6">
                    Categories <span className="text-red-500">*</span>
                </h4>
                {
                    projectCategories?.map((category, index)=>(
                        <div className="flex flex-col mb-2" key={index}>
                            <h3>
                                Category: {category?.category}
                            </h3>
                            <h3>
                                Sub-category: {category?.sub_category}
                            </h3>
                        </div>
                    ))
                }

                
                <h4 className="mt-6 font-[600]">
                  Notifications <span className="text-red-500">*</span>
                </h4>
                <p>
                    Phone Notifications: &nbsp;
                    {
                        projectData?.phone_notification ? "True":"False"
                    }
                </p>
                <p>
                    Email Notifications: &nbsp;
                    {
                        projectData?.email_notification ? "True" : "False"
                    }
                </p>

                <h4 className="mt-6 font-[600]">
                  Budget <span className="text-red-500">*</span>
                </h4>
                <p>GHS{projectData?.estimated_budget}</p>

                
              </div>
            )}

            {activeTab === 5 && (
              <div className="py-16">
                <h2 className="text-center text-[30px] font-[500]">
                  Project Submitted Successfully!
                </h2>
                <p className="text-center">
                  Thank you for using <b>Epahubb</b>
                </p>
                <div className="flex items-center justify-center my-7">
                  <img src={success_img} alt="" />
                </div>
              </div>
            )}
          </div>
          {activeTab !== 5 && (
            <div
              className={`flex items-center ${
                activeTab > 1 ? "justify-between" : "justify-end"
              }`}
            >
              {activeTab > 1 && (
                <button
                  onClick={() => setActiveTab(activeTab - 1)}
                  className="px-7 py-2 rounded bg-gray-300"
                >
                  Previous
                </button>
              )}
              {
                activeTab < 4 && (
                <button
                    onClick={() => {
                        validateFormData()
                        setActiveTab(activeTab + 1)
                    }}
                    className="px-7 py-2 bg-blue-500 rounded text-white "
                >
                    Next
                </button>
                )
              }
              {
                activeTab === 4 && (
                    <div>
                        {
                            submittingProject ? (
                                <RotatingLines 
                                    visible={true}
                                    height="26"
                                    width="26"
                                    strokeColor="black"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    ariaLabel="rotating-lines-loading"

                                />
                            ):(
                                <button
                                    onClick={() => initiateProject()}
                                    className="px-7 py-2 bg-blue-500 rounded text-white "
                                >
                                    Submit
                                </button>
                            )
                        }
                    </div>
                )
              }
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TryEpahubb;
