import axios from 'axios'
import React, { useState } from 'react'
import { databaseUrl } from '../../utils/data'
import Loading from '../../components/Loading'

const PurchaseTracking = () => {
    const [purchaseId, setPurchaseId] = useState(null)
    const [purchaseDetails, setPurchaseDetails] = useState(null)
    const [gettingDetails, setGettingDetails] = useState(false)
    const [notFound, setNotFound] = useState(false)

    const trackPurchase = async (e) => {
        e.preventDefault()
        setGettingDetails(true)
        setPurchaseDetails(null)
        setNotFound(false)
        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        await axios.get(`${databaseUrl}/data-bundles/purchase/${purchaseId}/`, config).then((response)=>{
            setPurchaseDetails(response?.data)
            setGettingDetails(false)
        }).catch((error)=>{
            setNotFound(true)
            setGettingDetails(false)
        });
    }
  return (
    <div className="py-20">
        {
            gettingDetails && <Loading />
        }
        <div className="lg:w-[80%] lg:px-0 px-10 m-auto">
            <h2 className='text-center font-bold text-[40px]'>Purchase Tracking</h2>
            <p className='mt-3 text-center  m-auto'>
                To track your purchase please enter your Purchase ID in the box below and press the "Track" button. This was given to you in the confirmation email you should have received.
            </p>
            {
                notFound && (
                    <div className='text-center mt-7 border-t-[4px] border-red-500 bg-gray-100 py-3 px-5'>
                        Sorry, the order could not be found. Please contact us if you are having difficulty finding your order details.
                    </div>
                )
            }

            <form onSubmit={(e)=>trackPurchase(e)} className='mt-10'>
                <div className="flex flex-col">
                    <label htmlFor="">Purchase ID</label>
                    <input type="text" className='border border-black px-2 py-3' required onChange={(e)=>setPurchaseId(e.target.value)} />
                </div>
                <button type="submit" className='bg-blue-500 w-full py-3 text-white mt-3'>Track</button>
            </form>
        </div>
        {
            purchaseDetails && (
                <div className="container py-10 border-t-[4px] border-green-500 mt-10">
                    <h3 className='font-bold mb-4 text-[30px] text-center'>Purchase Summary</h3>
                    <div className="grid lg:grid-cols-1 md:grid-cols-2">
                        <div className="grid lg:grid-cols-3 gap-3">
                            <div>
                                <p className='font-bold'>
                                    Purchase ID
                                </p>  
                                <p>
                                    {purchaseDetails?.purchase_id}
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Data Purchased
                                </p>  
                                <p>
                                    {purchaseDetails?.data_bundle_details} 
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Date Purchased
                                </p>  
                                <p>
                                    {purchaseDetails?.date_purchased}
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Data Cost
                                </p>  
                                <p>
                                GHS{purchaseDetails?.bundle_cost}
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Beneficiary Phone number 
                                </p>  
                                <p>
                                {purchaseDetails?.beneficiary_phone_number}
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Purchased By
                                </p>  
                                <p>
                                    {purchaseDetails?.full_name} ({purchaseDetails?.email_address})
                                </p>      
                            </div>
                            <div>
                                <p className='font-bold'>
                                    Data Processing Status
                                </p>  
                                <p>
                                    {
                                        purchaseDetails?.data_status === "Pending" && (
                                            <p className='text-yellow-500 bg-yellow-100 text-[12px] px-3 py-2 font-bold w-max rounded'>Pending</p>
                                        )
                                    }
                                    {
                                        purchaseDetails?.data_status === "Complete" && (
                                            <p className='text-green-500 bg-green-100 text-[12px] px-3 py-2 font-bold w-max rounded'>Complete</p>
                                        )
                                    }
                                    
                                </p>      
                            </div>
                            {
                                purchaseDetails?.data_status === 'Complete' && (
                                    <div>
                                        <p className='font-bold'>
                                            Date Distributed
                                        </p>  
                                        <p>
                                            {purchaseDetails?.data_sent_date}
                                        </p>      
                                    </div>
                                )
                            }
                        </div>
                       
                    </div>
                </div>
            )
        }
    </div>
  )
}

export default PurchaseTracking