import axios from "axios";
import React, { useEffect, useState } from "react";
import { databaseUrl } from "../../utils/data";
import Loading from "../../components/Loading";
import { Link } from "react-router-dom";

const DataPackages = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [gettingBundles, setGettingBundles] = useState(false)
  const [mtnBundles, setMtnBundles] = useState([]);
  const [atBundles, setAtBundles] = useState([]);
  const [telecelBundles, setTelecelBundled] = useState([]);

  const getDataBundles = async () => {
    setGettingBundles(true)
    const config = {
      "Content-Type": "application/json",
    };

    await axios
      .get(`${databaseUrl}/data-bundles/bundle-list/`, config)
      .then((response) => {
        let mtn_bundles = response.data?.filter(
          (bundle) => bundle.network === "MTN"
        );
        let at_bundles = response.data?.filter(
          (bundle) => bundle.network === "AT"
        );
        let telecel_bundles = response.data?.filter(
          (bundle) => bundle.network === "Telecel"
        );
        setMtnBundles(mtn_bundles);
        setAtBundles(at_bundles);
        setTelecelBundled(telecel_bundles);
        setGettingBundles(false)
      })
      .catch((error) => {
        if(error){
          window.location.href = "/"
        }
      });
  };

  useEffect(() => {
    getDataBundles();
  }, []);

  return (
    <div>
      <div className="h-[50vh] banner-bg flex flex-col items-center justify-center text-white">
        <h2 className="font-[700] lg:text-[90px] text-center md:text-[50px] text-[30px]">
          Data Packages
        </h2>
        <p className="lg:w-[70%] lg:text-[18px] md:text-[15px] text-[12px] text-center md:px-20 px-10">
          We have other amazing products and special offers that suit your
          communication and browsing needs. We believe your mobile experience
          should be efficient and easy. We're here to make life simple, with
          superior experiences that meet all your communication needs.
        </p>

        <Link to="/purchase-tracking" className="bg-blue-600 rounded-full px-16 mt-10 py-3">Purchase Tracking</Link>  
      </div>
      
      {
        gettingBundles ? (
          <Loading />
        ):(
          <div className="py-20 container">
            <div className="flex gap-2">
              <div
                className={`border border-gray-500 px-3 py-2 rounded min-w-32 text-center cursor-pointer ${
                  activeTab === 1
                    ? "bg-yellow-300 border-yellow-600"
                    : "bg-transparent text-inherit "
                }`}
                onClick={() => setActiveTab(1)}
              >
                MTN
              </div>
              <div
                className={`border border-gray-500 px-3 py-2 rounded min-w-32 text-center cursor-pointer ${
                  activeTab === 2
                    ? "bg-blue-500 border-blue-300 text-white"
                    : "bg-transparent text-inherit "
                }`}
                onClick={() => setActiveTab(2)}
              >
                AT
              </div>
              <div
                className={`border border-gray-500 px-3 py-2 rounded min-w-32 text-center cursor-pointer ${
                  activeTab === 3
                    ? "bg-red-500 border-red-300 text-white"
                    : "bg-transparent text-inherit "
                }`}
                onClick={() => setActiveTab(3)}
              >
                Telecel
              </div>
            </div>
            <div className="mt-10">
              {activeTab === 1 && (
                <div>
                    <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
                    {mtnBundles?.map((bundle) => (
                        <div className="border border-gray-500 flex flex-col items-center">
                        <div className="py-5 text-center">
                            <p>
                            {bundle.data_size}GB {bundle.network} DATA (
                            {bundle.data_type})
                            </p>
                            <p className="font-[600] ">GHS {bundle.cost}</p>
                        </div>
                        <Link to={`/data-packages/${bundle?.id}/checkout`} className="bg-green-700 w-full py-2 text-white text-center cursor-pointer hover:text-green-700 hover:bg-transparent border border-green-700">
                            Purchase
                        </Link>
                        </div>
                    ))}
                    </div>
                    {mtnBundles?.length === 0 && (
                    <p>There are not bundle packages for this network</p>
                  )}
                </div>
              )}

              {activeTab === 2 && (
                <div>
                  <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-5">
                    {atBundles?.map((bundle) => (
                      <div className="border border-gray-500 flex flex-col items-center">
                        <div className="py-5 text-center">
                          <p>
                            {bundle.data_size}GB {bundle.network} DATA (
                            {bundle.data_type})
                          </p>
                          <p className="font-[600] ">GHS {bundle.cost}</p>
                        </div>
                        <Link to={`/data-packages/${bundle?.id}/checkout`}  className="bg-blue-700 text-center w-full py-2 text-white cursor-pointer hover:text-blue-700 hover:bg-transparent border border-blue-700">
                          Purchase
                        </Link>
                      </div>
                    ))}
                  </div>
                  {atBundles?.length === 0 && (
                    <p>There are not bundle packages for this network</p>
                  )}
                </div>
              )}

              {activeTab === 3 && (
                <div>
                  <div className="grid grid-cols-4 gap-5">
                    {telecelBundles?.map((bundle) => (
                      <div className="border border-gray-500 flex flex-col items-center">
                        <div className="py-5 text-center">
                          <p>
                            {bundle.data_size}GB {bundle.network} DATA (
                            {bundle.data_type})
                          </p>
                          <p className="font-[600] ">GHS {bundle.cost}</p>
                        </div>
                        <Link to={`/data-packages/${bundle?.id}/checkout`}  className="bg-red-700 text-center w-full py-2 text-white cursor-pointer hover:text-red-700 hover:bg-transparent border border-red-700">
                          Purchase
                        </Link>
                      </div>
                    ))}
                  </div>
                  {telecelBundles?.length === 0 && (
                    <p>There are not bundle packages for this network</p>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }
    </div>
  );
};

export default DataPackages;
