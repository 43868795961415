import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../sections/Hero'
import Features from '../sections/Features'
import Skills from '../sections/Skills'
import About from '../sections/About'
import Services from '../sections/Services'
import Customers from '../sections/Customers'
import Brands from '../sections/Brands'
import Plan from '../sections/Plan'
import Contact from '../sections/Contact'
import Footer from '../sections/Footer'
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const HomePage = () => {
  return (
    <div>
        
        <Hero />
        <Features />
        <Skills />
        <About />
        <Services />
        <Customers />
        <Brands />
        <Plan />
        <Contact />
        
        <TawkMessengerReact
                propertyId="66fa65e64cbc4814f7e0db5b"
                widgetId="1i911s45b"/>
    </div>
  )
}

export default HomePage