import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import axios from "axios";
import { databaseUrl } from "../../utils/data";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PaystackPop from "@paystack/inline-js"

const Checkout = () => {
  const {package_id} = useParams()
  const [acceptance, setAcceptance] = useState(false)
  const [packageInfo, setPackageInfo] = useState({})
  const [gettingData, setGettingData] = useState(false)
  const [purchasingData, setPurchasingData] = useState(false)
  const [data, setData] = useState({
    data_bundle : package_id
  })
  const publickKey = "pk_live_2c21a8812a6a2f8e80e2da30d7026de6222aa334"
  // const publickKey = "pk_test_b280bd842436239d7059d65481bd50c960db4302"

  const handleChange = (e) => {
    setData({
      ...data, [e.target.name] : e.target.value
    })
  }

  const onSuccess = async (response) => {
    
    data['reference'] = response?.reference
    
    const config = {
      'Content-Type':"application/json",
      'Accept': 'application/json',
    }
    
    const body = JSON.stringify(data)
    
    setPurchasingData(true)
    await axios.post(`${databaseUrl}/data-bundles/purchase/`,body, config).then((response)=>{
      setPurchasingData(false)
      Swal.fire({
        title: response?.data?.title,
        text: response?.data?.text,
        icon: response?.data?.icon
      });
    }).catch((error)=>{
      
      if(error?.response?.data?.icon === "error"){
        Swal.fire({
          title: error?.response?.data?.title,
          text: error?.response?.data?.text,
          icon: "error"
        });
      }
      setPurchasingData(false)
    })
    
  }

  const placeOrder = (e) =>{
    e.preventDefault()
    if(data?.beneficiary_phone_number?.length < 10){
      Swal.fire({
        title:"Invalid Phone number",
        text:"The beneficiary's phone number is not valid. Please enter a 10 digit phone number",
        icon:"error"
      })
      return
    }
    Swal.fire({
      title: "Do you want to proceed?",
      text: `You are about to purchase ${packageInfo?.data_size}${packageInfo?.data_unit} ${packageInfo?.network} ${packageInfo?.data_type} for ${data?.beneficiary_phone_number}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed."
    }).then((result) => {
      if (result.isConfirmed) {
        let handler = PaystackPop.setup({
          key: publickKey,
          reference: new Date().getTime().toString(),
          email: data?.email_address,
          amount: packageInfo?.cost * 100,
          currency: 'GHS',
          onClose: ()=>console.log("Closed"),
          onSuccess: (response)=>onSuccess(response)
        })
        handler.openIframe();
        
      }
    });
  }

  const getPackageInformation = async () => {
    setGettingData(true)
    await axios.get(`${databaseUrl}/data-bundles/${package_id}/detail/`).then((response)=>{
      setPackageInfo(response?.data)
      setGettingData(false)
    }).catch((error)=>{
      if(error?.response?.status === 404){
        window.location.href = "/data-packages"
      }
      setGettingData(false)
    })
  }

  useEffect(()=>{
    getPackageInformation()
  },[])

  return (
    <div className="py-10 container">
      <h1 className="text-center font-[600] text-[40px]">Checkout</h1>
      {
        purchasingData && (
          <Loading />
        )
      }
      {
        gettingData ? (
          <Loading />
        ):(
        <form onSubmit={(e)=>placeOrder(e)} className="grid lg:grid-cols-2 gap-20">
          <div className="mt-5">
            <h3 className="font-[500] text-[20px]">Billing Details</h3>
            <div action="" className="mt-4">
              <div className="flex flex-col">
                <label htmlFor="">
                  Your Name <span className="text-red-500">*</span>
                </label>
                <input required onChange={(e)=>handleChange(e)} type="text" name="full_name" id="" className="border border-slate-500 px-2 py-3" />
              </div>

              <div className="flex flex-col mt-3">
                <label htmlFor="">
                  Your Phone Number <span className="text-red-500">*</span>
                </label>
                <input required onChange={(e)=>handleChange(e)} type="text" name="" id="" className="border border-slate-500 px-2 py-3" />
              </div>

              <div className="flex flex-col mt-3">
                <label htmlFor="">
                  Your Email <span className="text-red-500">*</span>
                </label>
                <input required onChange={(e)=>handleChange(e)} type="text" name="email_address" id="" className="border border-slate-500 px-2 py-3" />
              </div> 

              <div className="flex flex-col mt-3">
                <label htmlFor="">
                  Beneficiary's Phone Number <span className="text-red-500">*</span>
                </label>
                <div className="flex flex-col">
                  <input placeholder="e.g 0541754545" required onChange={(e)=>handleChange(e)} type="text" name="beneficiary_phone_number" id="" className="border border-slate-500 px-2 py-3" />
                  <small>Please enter the phone number of the person receiving the data. Make sure the phone number is correct, we will not be responsible if you enter the wrong number.</small>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <h3 className="font-[500] text-[20px]">Your Order</h3>
            <div className="mt-4">
              <table className="w-full">
                <thead className="text-left border border-gray-400">
                  <th className="p-2">
                    PRODUCT
                  </th>
                  <th>
                    COST
                  </th>
                </thead>
                <tbody>
                  <tr className="text-left border border-gray-400">
                    <td className="p-2">
                      {packageInfo?.data_size} {packageInfo?.data_unit} {packageInfo?.network} {packageInfo?.data_type}
                    </td>
                    <td>
                      GHS {packageInfo?.cost}
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <input required type="checkbox" name="" onClick={()=>setAcceptance(!acceptance)} id="" /> I Accept that all provided information are correct
              <br />
              <button className="bg-blue-500 px-3 py-2 text-white">
                Proceed to payment
              </button>
            </div>
          </div>
        </form>
        )
      }
    </div>
  );
};

export default Checkout;
