import React from "react";

const Loading = () => {
  return (
    <div className="flex flex-col" id="kt_app_root">
      <div className="bg-spinner">
        <div
          id="position-spinner"
          className="ml-0 flex flex-col justify-center gap-[20px]"
        >
          <span id="spinner"></span>
          
        </div>
      </div>
    </div>
  );
};

export default Loading;
