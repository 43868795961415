
import { useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import AOS from "aos";
import "aos/dist/aos.css";
import 'react-phone-number-input/style.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './sections/Footer';
import DataPackages from './pages/DataPurchase/DataPackages';
import Checkout from './pages/DataPurchase/Checkout';
import PurchaseTracking from './pages/DataPurchase/PurchaseTracking';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
   <BrowserRouter>
      <Navbar />
      <div className='pt-20'>
        <Routes>
          <Route path='' element={<HomePage />} />
          <Route path="/data-packages" element={<DataPackages />} />
          <Route path="/data-packages/:package_id/checkout" element={<Checkout />} />
          <Route path="/purchase-tracking" element={<PurchaseTracking />} />
        </Routes>
      </div>
      <Footer />
   </BrowserRouter>
  );
}

export default App;
